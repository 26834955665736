import React from 'react'
import Helmet from 'react-helmet'

export default ({
  lang = 'en-us',
  siteTitle,
  defaultDescription,
  pageTitle,
  description,
  shareImageUrl,
  defaultShareImageUrl,
}) => {
  const metaDescription = description ? description : defaultDescription
  const expandedTitle = pageTitle ? `${pageTitle} | ${siteTitle}` : siteTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={expandedTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: expandedTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: shareImageUrl ?? defaultShareImageUrl ?? '',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:image`,
          content: shareImageUrl ?? defaultShareImageUrl ?? '',
        },
        {
          name: `twitter:title`,
          content: expandedTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}
