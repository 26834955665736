import React from 'react'
import t from 'prop-types'
import { Link } from 'gatsby'

const MenuDesktop = ({ links }) => {
  return (
    <nav className="hidden md:flex justify-center space-x-8 -mb-3">
      {links.map(link => {
        return (
          <Link
            to={link.path}
            key={link.path}
            className="text-white hover:text-opacity-70 text-lg 
              transition font-bold text-center inline-block 
              no-underline border-b border-white 
              border-opacity-0 hover:border-opacity-70 pb-2"
            activeClassName="border-opacity-70"
            partiallyActive
          >
            {link.text}
          </Link>
        )
      })}
    </nav>
  )
}

MenuDesktop.propTypes = {
  links: t.arrayOf(
    t.shape({
      path: t.string,
      text: t.string,
    })
  ),
}

MenuDesktop.defaultProps = {}

export default MenuDesktop
