import React from 'react'
import t from 'prop-types'
import useGlobalSettings from '../../queries/useGlobalSettings'
import Head from '../Head'
import Header from '../header'
import Footer from '../Footer'
import NotificationBar from '../NotificationBar'

const transformData = data => {
  return {
    headProps: {
      siteTitle: data.seo_site_title,
      defaultDescription: data.seo_default_page_description,
      pageTitle: data.seo.title,
      description: data.seo.description,
      defaultShareImageUrl: data.seo_share_image_fallback?.url,
      shareImageUrl: data.seo.shareImageUrl,
    },

    menuProps: {
      links: data.header_menu.map(menuItem => {
        return {
          text: menuItem.header_menu_link_text,
          path: menuItem.header_menu_link.url,
        }
      }),
    },

    footerProps: {
      links: data.footer_page_links.map(footerPageLink => {
        return {
          path: footerPageLink.footer_page_links_link.url,
          text: footerPageLink.footer_page_links_text,
        }
      }),
      sites: {
        heading: data.footer_site_links_heading,
        links: data.footer_site_links.map(footerSiteLink => {
          return {
            path: footerSiteLink.footer_site_links_link.url,
            text: footerSiteLink.footer_site_links_link_text,
          }
        }),
      },
      press: {
        heading: data.footer_contact_heading,
        link: data.footer_contact_email,
      },
      appleLink: data.app_store_link.url,
      googleLink: data.play_store_link.url,
      apkLink: data.apk_link.url,
      copyright: data.footer_copyright,
      builtBy: data.footer_built_by_text,
    },
    notificationBarProps: {
      display: data.notification_bar_display,
      text: data.notification_bar_text,
      link: {
        text: data.notification_bar_link_text,
        path: data.notification_bar_link.url,
      },
    },
  }
}

const PageWrap = ({ seo, children }) => {
  const globalSettings = useGlobalSettings()

  const {
    headProps,
    menuProps,
    footerProps,
    notificationBarProps,
  } = transformData({
    seo,
    ...globalSettings,
  })

  return (
    <>
      <Head {...headProps} />
      <div className="antialiased font-sans">
        {notificationBarProps.display && (
          <NotificationBar {...notificationBarProps} />
        )}
        <div className="relative" style={{ zIndex: 1000 }}>
          <div className="absolute z-50 w-full left-0 top-0">
            <Header menuProps={menuProps} mobileMenuProps={footerProps} />
          </div>
        </div>
        {children}
      </div>
    </>
  )
}

PageWrap.propTypes = {
  seo: t.shape({
    description: t.string,
    title: t.string,
  }),
  children: t.node.isRequired,
}

PageWrap.defaultProps = {}

export default PageWrap
