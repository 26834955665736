import React from 'react'
import { css } from 'linaria'
import PageWrap from '../../components/PageWrap'
import DownloadMantel from '../../components/DownloadMantel'
import ContentWrap from '../../components/ContentWrap'
import HomepageLine from './HomepageLine'

const styles = css`
  .home-line-spacer {
    position: absolute !important;
  }
`

const Homepage = ({ mantelProps, seoProps }) => {
  return (
    <PageWrap seo={seoProps}>
      <div className={`${styles} relative min-h-screen overflow-hidden`}>
        <div className="absolute z-0">
          <HomepageLine duration="15s" />
        </div>
        <ContentWrap className="relative z-50">
          <div className="space-y-28 pb-24 pt-24 md:pt-36">
            <DownloadMantel {...mantelProps} />
          </div>
        </ContentWrap>
      </div>
    </PageWrap>
  )
}

export default Homepage
