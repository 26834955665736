import React from 'react'
import t from 'prop-types'
import { styled } from 'linaria/react'
import pattern from '../../images/bg-pattern-white-small.png'
import { Link } from 'gatsby'
import CtaArrow from '../CtaArrow/CtaArrow'

const Container = styled.article`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${pattern}) no-repeat bottom left;
  background-size: 50%;
`

const CtaTout = ({ link, heading, title }) => {
  return (
    <Link to={link} className="block no-underline bg-white">
      <Container>
        <div className="flex items-center p-6 md:p-10 shadow-xl hover:shadow-2xl transition ">
          <CtaArrow className="w-10 md:w-16 mr-2 md:mr-4" />
          <div className="ml-2">
            <h3 className="text-black font-bold text-lg md:text-2xl leading-tight">
              {heading}
            </h3>
            <div className="text-link font-body flex items-center">
              {title}
              <svg
                width="15"
                height="11"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 my-auto fill-current"
              >
                <path
                  d="M15 5.5a.679.679 0 00-.162-.398L10.222.181a.576.576 0 00-.815-.023c-.225.203-.23.599-.023.819l3.705 3.944H.577a.578.578 0 00-.577.58c0 .32.258.578.577.578h12.512l-3.705 3.944c-.207.22-.193.606.023.819a.576.576 0 00.815-.023l4.616-4.92A.517.517 0 0015 5.5z"
                  fillRule="nonzero"
                />
              </svg>
            </div>
          </div>
        </div>
      </Container>
    </Link>
  )
}

CtaTout.propTypes = {
  heading: t.string,
  link: t.string,
  title: t.string,
}

CtaTout.defaultProps = {}

export default CtaTout
