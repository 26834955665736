import { useRef, useEffect } from 'react'

export default () => {
  const focusableRefs = useRef([])

  useEffect(() => {
    function handleKeyPress(e) {
      console.log(focusableRefs.current)
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9
      const lastFocusableElement =
        focusableRefs.current[focusableRefs.current.length - 1]
      const firstFocusableElement = focusableRefs.current[0]

      if (!isTabPressed) {
        return
      }

      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus() // add focus for the last focusable element
          e.preventDefault()
        }
      } else {
        // if tab key is pressed
        if (document.activeElement === lastFocusableElement) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus() // add focus for the first focusable element
          e.preventDefault()
        }
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  })

  return {
    pushFocusableRef: ref => focusableRefs.current.push(ref),
  }
}
