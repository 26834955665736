import { useStaticQuery, graphql } from 'gatsby'

export default function useGlobalSettings() {
  const { prismicGlobalSettings } = useStaticQuery(graphql`
    query {
      prismicGlobalSettings {
        data {
          seo_site_title
          seo_default_page_description
          seo_share_image_fallback {
            url
          }
          header_menu {
            header_menu_link_text
            header_menu_link {
              url
            }
          }
          footer_page_links {
            footer_page_links_text
            footer_page_links_link {
              url
            }
          }
          footer_site_links_heading
          footer_site_links {
            footer_site_links_link_text
            footer_site_links_link {
              url
            }
          }
          footer_contact_heading
          footer_contact_email
          footer_copyright
          footer_built_by_text
          app_store_link {
            url
          }
          play_store_link {
            url
          }
          apk_link {
            url
          }
          notification_bar_display
          notification_bar_text
          notification_bar_link_text
          notification_bar_link {
            url
          }
        }
      }
    }
  `)

  return prismicGlobalSettings.data
}
