import React from 'react'
import t from 'prop-types'

const ContentWrap = ({ children, className = '' }) => {
  return (
    <div className={`max-w-7xl mx-auto px-4 box-border ${className}`}>
      {children}
    </div>
  )
}

ContentWrap.propTypes = {
  children: t.node.isRequired,
}

ContentWrap.defaultProps = {}

export default ContentWrap
