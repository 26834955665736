import React from 'react'
import t from 'prop-types'
import { styled } from 'linaria/react'
import { Link } from 'gatsby'
import footer from '../../images/footer-texture.svg'
import logo from '../../images/algorand-wallet-logo.svg'
import google from '../../images/app-store-icon-google.png'
import apple from '../../images/app-store-icon-apple.png'
import apkBadge from '../../images/apk-file-badge.png'

const Container = styled.footer`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${footer}) no-repeat bottom left;
  background-size: cover;
  position: relative;
  z-index: 10;
`

const Footer = ({
  googleLink,
  appleLink,
  apkLink,
  links,
  sites,
  press,
  copyright,
  builtBy,
}) => {
  return (
    <Container>
      <div className="footer-desktop container mx-auto hidden md:grid grid-cols-3 pt-12">
        <div>
          <div className="mb-6">
            <Link to="/">
              <img src={logo} alt="Algorand Wallet" />
            </Link>
            <div
              className="font-normal leading-none text-white pt -mx-px"
              style={{ fontSize: '17.35px' }}
            >
              {builtBy}
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <a href={googleLink} target="_blank">
              <img width="157px" height="46px" src={google} alt="Google Play" />
            </a>
            <a href={appleLink} target="_blank">
              <img
                width="157px"
                height="46px"
                src={apple}
                alt="Apple App Store"
              />
            </a>
            <a href={apkLink} target="_blank">
              <img
                width="157px"
                height="46px"
                src={apkBadge}
                alt="Download APK Android"
              />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-2 -mt-1.5">
          {links.map(link => {
            return (
              <Link
                to={link.path}
                key={link.path}
                className="text-white hover:text-opacity-70 text-lg 
                transition font-bold text-center inline-block 
                no-underline border-b border-white 
                border-opacity-0 hover:border-opacity-70 pb-2"
              >
                {link.text}
              </Link>
            )
          })}
        </div>
        <div className="text-right text-white leading-none -mt-0.5">
          <div className="text-xs font-bold text-pink uppercase mb-4">
            {sites.heading}
          </div>
          {sites.links.map(link => {
            return (
              <Link
                to={link.path}
                key={link.path}
                className="text-white font-bold text-xs block mb-2 hover:underline"
              >
                {link.text}
              </Link>
            )
          })}
          <div className="text-xs font-bold text-pink uppercase mt-12 mb-4">
            {press.heading}
          </div>
          <a
            href={`mailto:${press.link}`}
            className="text-xs text-white font-bold hover:underline"
          >
            {press.link}
          </a>
        </div>
      </div>
      <div className="bg-gradient-to-r from-cerise to-magenta text-white text-center font-normal text-sm py-5 mt-12 hidden md:block">
        {copyright}
      </div>

      <div className="footer-mobile md:hidden py-5">
        <Link
          className="text-white text-base font-normal text-center underline block mb-10"
          to="#"
        >
          Back to top
        </Link>
        <img className="mx-auto" src={logo} alt="Algorand Wallet" />
      </div>
    </Container>
  )
}

Footer.propTypes = {
  copyright: t.string,
  googleLink: t.string,
  appleLink: t.string,
  links: t.arrayOf(
    t.shape({
      path: t.string,
      text: t.string,
    })
  ),
  sites: t.shape({
    heading: t.string,
    links: t.arrayOf(
      t.shape({
        path: t.string,
        text: t.string,
      })
    ),
  }),
  press: t.shape({
    heading: t.string,
    link: t.string,
  }),
}

Footer.defaultProps = {}

export default Footer
