import React from 'react'
import t from 'prop-types'
import { Link } from 'gatsby'

const NotificationBar = ({ text, link }) => {
  return (
    <div className="bg-black font-bold text-base text-white text-center py-3.5 block md:flex md:justify-center w-full">
      <div>{text}</div>
      <Link to={link.path} className="text-white underline hover:no-underline ml-3">
        {link.text}
      </Link>
    </div>
  )
}

NotificationBar.propTypes = {
  text: t.string,
  link: t.shape({
    path: t.string,
    text: t.string, 
  })
}

NotificationBar.defaultProps = {}

export default NotificationBar