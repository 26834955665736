import React from 'react'
import t from 'prop-types'
import Fade from 'react-reveal/Fade'
import CtaTout from '../CtaTout'
import peraImage from '../../images/pera-first-screen.png'

const DownloadMantel = ({ heading, subheading, intro, cta }) => {
  return (
    <div className="flex flex-col-reverse md:flex-row items-center">
      <div className="md:w-6/12 md:pt-28 bg-white bg-opacity-60 p-4 md:p-0 md:bg-transparent">
        <Fade ssrReveal left duration={700} distance="150px">
          <p className="mb-2 text-xl md:text-3xl font-semibold">{intro}</p>
          <h2 className="mb-8 text-2xl md:text-5xxl font-bold md:leading-tight">
            {heading}
          </h2>
          {subheading && (
            <p
              className="mt-0 mb-0 text-lg md:text-2xl"
              dangerouslySetInnerHTML={{ __html: subheading }}
            ></p>
          )}
        </Fade>
      </div>
      <div className="flex justify-center items-center md:pl-6 w-3/5 md:w-7/12">
        <Fade ssrReveal right duration={800} distance="150px">
          <img src={peraImage} alt={`Pera Wallet App`} />
        </Fade>
      </div>
    </div>
  )
}

DownloadMantel.propTypes = {
  heading: t.string.isRequired,
  subheading: t.string,
  appStoreLink: t.string.isRequired,
  playStoreLink: t.string.isRequired,
  img: t.shape({
    src: t.string,
    alt: t.string,
  }),
  cta: t.shape({
    heading: t.string.isRequired,
    linkText: t.string.isRequired,
    link: t.string.isRequired,
  }),
}

DownloadMantel.defaultProps = {}

export default DownloadMantel
