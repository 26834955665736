import React, { useState } from 'react'
import t from 'prop-types'
import { styled } from 'linaria/react'
import { Link } from 'gatsby'
import bg from '../../images/mobile-menu-pattern.svg'
import logo from '../../images/algorand-wallet-logo.svg'
import google from '../../images/app-store-icon-google.png'
import apple from '../../images/app-store-icon-apple.png'
import hamburger from '../../images/icon-hamburger.svg'
import close from '../../images/icon-close.svg'
import useFocusCycle from '../../hooks/useFocusCycle'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'

const Container = styled.div`
  @apply pt-14 text-center flex flex-col items-center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${bg}) no-repeat top center;
  background-size: cover;
`
const BottomBar = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
`

const MenuMobile = ({
  googleLink,
  appleLink,
  links,
  sites,
  press,
  copyright,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { pushFocusableRef } = useFocusCycle()

  return (
    <div className="lg:hidden">
      <div className="flex px-2 bg-gradient-to-r from-magenta to-cerise items-center  justify-between  py-3.5">
        <Link to="/">
          <img width="148px" height="23px" src={logo} alt="Algorand Wallet" />
        </Link>
      </div>
    </div>
  )
}

MenuMobile.propTypes = {
  copyright: t.string,
  googleLink: t.string,
  appleLink: t.string,
  links: t.arrayOf(
    t.shape({
      path: t.string,
      text: t.string,
    })
  ),
  sites: t.shape({
    heading: t.string,
    links: t.arrayOf(
      t.shape({
        path: t.string,
        text: t.string,
      })
    ),
  }),
  press: t.shape({
    heading: t.string,
    link: t.string,
  }),
}

MenuMobile.defaultProps = {}

export default MenuMobile
