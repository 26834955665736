import React from 'react'

const CtaArrow = ({ className }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 58 58" className={className}>
      <g
        id="design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="aw-home-v3b"
          transform="translate(-160.000000, -711.000000)"
          fill="#000000"
          fillRule="nonzero"
        >
          <g id="cta" transform="translate(120.000000, 671.000000)">
            <g
              id="np_arrow_2732868_000000"
              transform="translate(40.000000, 40.000000)"
            >
              <path
                d="M29.34,0.582 C13.602,0.582 0.84,13.344 0.84,29.082 C0.84,44.82 13.602,57.582 29.34,57.582 C45.078,57.582 57.84,44.82 57.84,29.082 C57.84,13.344 45.078,0.582 29.34,0.582 Z M25.6955,38.461 L24.2853,37.043 L32.2892,29.0703 L24.2697,20.7773 L25.7072,19.3867 L35.0978,29.0937 L25.6955,38.461 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

CtaArrow.propTypes = {}

CtaArrow.defaultProps = {}

export default CtaArrow
