import React from 'react'
import { Link } from 'gatsby'
import logo from '../images/algorand-wallet-logo.svg'
import MenuMobile from './MenuMobile/MenuMobile'
import MenuDesktop from './MenuDesktop/MenuDesktop'
import ContentWrap from '../components/ContentWrap'

const Header = ({ menuProps, mobileMenuProps }) => {
  return (
    <header>
      <ContentWrap>
        <div className="hidden lg:flex items-center pt-6">
          <Link to="/" className="mr-12 xl:mr-12">
            <img width={265} src={logo} alt="Algorand Wallet" />
          </Link>
        </div>
      </ContentWrap>
      <MenuMobile {...mobileMenuProps} />
    </header>
  )
}

export default Header
