import React from 'react'
import { useMediaQuery } from 'beautiful-react-hooks'
import { css } from 'linaria'

const styles = css`
  transform: translateX(-51.5%) translateY(-14px) scale(0.7);
  transform-origin: top center;

  @screen md {
    transform: translateX(-55.6%);
    transform-origin: auto;
  }
`

const HomepageLine = ({ duration }) => {
  // disabling animation for time being since safari performance is horrible
  const animateGradient = false //useMediaQuery('(min-width: 768px)')

  return (
    <svg
      width="4645px"
      height="5348px"
      viewBox="0 0 4645 5348"
      className={`${styles} z-0`}
      style={{
        left: `50vw`,
        top: 0,
        position: 'absolute',
      }}
    >
      <defs>
        <linearGradient
          x1="57.615239%"
          y1="41.270003%"
          x2="63.4823942%"
          y2="-4.576104%"
          id="linearGradient-3"
        >
          <stop stopColor="#F37E33" offset="30%">
            {animateGradient && (
              <animate
                attributeName="stop-color"
                values="#F37E33; #A30786; #F37E33;"
                dur={duration}
                repeatCount="indefinite"
              ></animate>
            )}
          </stop>
          <stop stopColor="#A30786" offset="100%">
            {animateGradient && (
              <animate
                attributeName="stop-color"
                values="#A30786; #F37E33; #A30786"
                dur={duration}
                repeatCount="indefinite"
              ></animate>
            )}
          </stop>
        </linearGradient>
        <rect id="path-1" x="0" y="0" width="4645" height="5348" />
      </defs>
      <g
        id="style-guide"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Artboard" transform="translate(151.000000, 0.000000)">
          <g id="Path-15" transform="translate(-151.000000, 0.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Mask" />
            <polygon
              fill="url(#linearGradient-3)"
              mask="url(#mask-2)"
              points="1380 7.14957607e-14 2888 305 190 5348 431 5348 3261 146 2430 0"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HomepageLine
