import { graphql } from 'gatsby'
import React from 'react'
import { withPreview } from 'gatsby-source-prismic'
import useGlobalSettings from '../queries/useGlobalSettings'
import Homepage from '../templates/Homepage'
import * as Icons from '../components/Icons'

const transformData = data => {
  return {
    seoProps: {
      title: data.meta_title,
      description: data.meta_description,
      shareImageUrl: data.share_image?.url,
    },
    mantelProps: {
      intro: 'We are excited to announce that',
      heading: 'Algorand Wallet is now Pera Wallet.',
      subheading:
        'You can read about the launch announcement of the Pera Wallet <a class="text-link" href="https://perawallet.app/blog/launch-announcement/">here</a>. To download the Pera Wallet app, or to learn more about Pera\'s roadmap, head over to <a class="text-link inline-flex" href="https://perawallet.app/">PeraWallet.app <svg width="18" height="13" viewBox="0 0 15 11" xmlns="http://www.w3.org/2000/svg" class="ml-2 my-auto fill-current"><path d="M15 5.5a.679.679 0 00-.162-.398L10.222.181a.576.576 0 00-.815-.023c-.225.203-.23.599-.023.819l3.705 3.944H.577a.578.578 0 00-.577.58c0 .32.258.578.577.578h12.512l-3.705 3.944c-.207.22-.193.606.023.819a.576.576 0 00.815-.023l4.616-4.92A.517.517 0 0015 5.5z" fill-rule="nonzero"></path></svg></a>',
    },
  }
}

const Index = ({ data }) => {
  return <Homepage {...transformData(data.prismicHomepage.data)} />
}

export default withPreview(Index)

export const pageQuery = graphql`
  query {
    prismicHomepage {
      data {
        meta_title
        meta_description
        share_image {
          url
        }
      }
    }
  }
`
